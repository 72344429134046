.contacts-wrapper {
    height: 100vh;
    padding: var(--page-padding);
    padding-top: 0vw;
    overflow: hidden;
    position: relative;
    min-width: 635px;
    display: flex;

    .media-contacts {
        display: flex;
        width: 110vh;
        height: 100vh;
        margin: 4vw auto;
        background-image: url(./images/contacts.svg);
        background-size: 100%;
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: 108% 45%;
        position: relative;
        z-index: 12;
        // border: 1px solid magenta;

        a {
            display: block;
            height: 20vh;
            width: 20vh;
            border-radius: 100%;
            cursor: pointer;
            position: absolute;
            // box-shadow: rgb(255, 255, 255) -0em -1em 2em 0.1em;
        }

        .github {
            left: 18.3%;
            top: 17.5%;
            // border: 1px solid rgb(255, 0, 0);
            // background-color: rgba(166, 249, 255, 0.336);
            // background: linear-gradient(#daf4ff, #f9f0fd);
            // background: linear-gradient(#def8ef, #c1c0ca);
        }

        .mail {
            left: 37.5%;
            top: 58%;
            // background-color: rgba(121, 141, 255, 0.336);
            // background: linear-gradient(#def8ef, #c1c0ca);
            // transform: rotate(194deg);
            // border: 1px solid rgb(136, 255, 0);
        }

        .twitter {
            left: 58%;
            top: 26%;
            // background-color: rgba(255, 121, 255, 0.336);
            // background: linear-gradient(#c8ffff, #c1c0ca);
            // border: 1px solid rgb(0, 26, 255);
        }

        .facebook {
            height: 10.2vh;
            width: 10.2vh;
            // border: 1px solid rgb(255, 0, 191);
            left: 88.8%;
            top: 19.2%;
            // background-color: rgba(255, 75, 150, 0.336);
            // background: linear-gradient(#def8ef, #c1c0ca);
        }
    }

    .linkedin {
        display: block;
        background-image: url(./images/linkedin.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 0;
        left: 16%;
        height: 24vh;
        width: 24vh;
        z-index: 12;
        cursor: pointer;
    }

    .background-ball {
        height: 90vh;
        width: 70vw;
        min-width: 40vw;
        filter: blur(7vw);
        // background: linear-gradient(123deg, #00ffc2 7%, #060028 30%);
        background: linear-gradient(137deg, #00d0ff 4%, #060028 65%);
        opacity: 0.23;
        border-radius: 100%;
        position: absolute;
        bottom: -19%;
        left: 16%;
        pointer-events: none;
    }
}
