.page-research {
    // padding: var(--page-padding);
    // border: 1px springgreen solid;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);
    // padding-bottom: var(--page-padding-bottom);
    --illustration-size: 103%;

    .menu-plug {
        width: 56vh;
        height: 53vh;
        position: fixed;
        z-index: 4;
        top: 0vh;
        left: -1.9vh;
        background-image: url(~icons/menu.svg);
        background-position: left;
        background-size: 110%;
        background-repeat: no-repeat;
        display: flex;
    }

    p {
        line-height: 1.75em;

        a {
            display: inline;
            cursor: pointer;
            color: #615ecc;
        }
    }

    h2,
    a {
        display: block;
        &.title-link {
            line-height: 1.35em;
        }
    }

    .info-block-wrapper {
        margin: 0 var(--equal-page-padding);
    }

    & > p,
    & > h2,
    & > a,
    .research-titles-wrapper {
        padding: 0 var(--equal-page-padding);
        // word-wrap: break-word;
    }

    .AI-people,
    .AI-people2,
    .mozg {
        width: 100vw;
        height: 40vh;
        background-image: url(./images/AIpeople.jpg);
        // background-image: url(./images/AIpeople3.webp);
        background-repeat: no-repeat;
        background-size: cover;
        transform: scale(1.2);
        margin: 10% 0%;
    }

    .AI-people2 {
        background-image: url(./images/AIpeople2.png);
    }

    .adapting-simulation {
        // background: -webkit-linear-gradient(to right, #615ecc 5%, #4ba3d1 3%, #34edd7 3%);
        // background: -moz-linear-gradient(to right, #615ecc 5%, #4ba3d1 3%, #34edd7 3%);
        // background: linear-gradient(to right, #615ecc 5%, #4ba3d1 3%, #34edd7 33%);
        background: -webkit-linear-gradient(to right, #615ecc 5%, #34edd7 70%);
        background: -moz-linear-gradient(to right, #615ecc 5%, #34edd7 70%);
        background: linear-gradient(to right, #615ecc 5%, #34edd7 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .mozg {
        background-image: url(./images/mosg_shirok.jpeg);
        background-position: center center;
        background-size: 90%;
    }

    .mozg2 {
        background-image: url(./images/new_tiny_brain.jpg);
        background-position: center center;
        // background-size: 25%;
        border-radius: 100%;
        // width: 100vw;
        height: 50vh;
        width: 50vh;
        background-repeat: no-repeat;
        background-size: 101%;
        margin: 10vh auto;
    }

    .non-smooth {
        background-image: url(./images/non_smooth.jpg);
        background-position: center center;
        height: 55vw;
        width: 55vw;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 10vh auto;
    }

    .tesseract {
        background-image: url(./images/tesseract.jpg);
        background-position: center center;
        height: 41vh;
        width: 100vh;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 10vh auto;
    }

    .in-hand {
        background-image: url(./images/in_hand.jpg);
        background-position: center center;
        height: 42vh;
        // width: 100vw;
        width: 80vw;
        background-repeat: no-repeat;
        background-size: cover;
        // margin: var(--page-padding-top) auto;
        margin: var(--equal-page-padding) auto;
    }

    .transferring-dexterous {
        background-image: url(./images/transferring_dexterous.jpg);
        background-position: center center;
        height: 23vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: var(--illustration-size);
        margin: 10vh auto;
    }

    .accelerated-policy,
    .accelerated-policy2 {
        background-image: url(./images/accelerated_policy.jpg);
        background-position: center center;
        height: 35vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: var(--illustration-size);
        margin: 10vh auto;
    }

    .accelerated-policy2 {
        background-image: url(./images/accelerated_policy2.jpg);
    }

    .rlgames {
        background-image: url(./images/door-key-curriculum.gif);
        background-position: center center;
        height: 70vh;
        width: 70vh;
        background-repeat: no-repeat;
        background-size: var(--illustration-size);
        margin: 10vh auto;
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
    }

    .envpool {
        background-image: url(./images/envpool.jpg);
        background-position: center center;
        height: 50vh;
        width: 100vh;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 10vh auto;
    }

    .oscar {
        margin: 10vh;
        display: flex;
        justify-content: center;

        &video {
            width: 30vw;
            height: 20vw;
        }
    }

    .reinforcement_learning {
        background-image: url(./images/reinforcement_learning.jpg);
        background-position: center center;
        height: 40vh;
        width: 100vh;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 10vh auto;
    }

    .dextreme {
        background-image: url(./images/dextreme.jpg);
        background-position: center center;
        height: 40vw;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: contain;
        margin: var(--equal-page-padding) auto;
        // margin: 10vh auto;
    }
}

@media (max-aspect-ratio: 8/7) {
    .page-research {
        padding: 23vh 0vh; /* ЭТО НЕ ТРОГАЕМ, иначе лишний пэддинг у100% элементов */

        .planet-wrapper {
            .planet,
            .inner-halo {
                top: -34.5vw;
            }
        }
        .research-titles-wrapper {
            padding: 2vh 7vh;
            // border: 1px solid rgb(140, 255, 240);
        }
    }
}

@media (max-aspect-ratio: 8/13) {
    .page-research {
        .planet-wrapper {
            .planet,
            .inner-halo {
                height: 34vw;
                width: 34vw;
            }
        }
        .research-titles-wrapper {
            padding: 0vh 6.5vh;
            // border: 1px solid rgb(255, 140, 140);
        }
    }
}
