.info-block-wrapper {
    // height: 27vw;
    // border: 1px solid black;
    // min-height: 30vh;
    display: flex;
    flex-direction: row;
    box-shadow: 0.1vh 0.1vh 3vh rgba(128, 128, 128, 0.397);
    border-radius: 2vh;

    &.visible {
        animation: appearing 0.85s ease-in-out;
    }

    &.text-right {
        flex-direction: row-reverse;

        .illustration {
            border-radius: 2vh 0vh 0vh 2vh;
            cursor: pointer;
        }
    }

    &.vertical {
        flex-direction: column;
        max-width: 50vw;
    }

    .info-wrapper {
        flex: 1;
        padding: 5vh;
        font-size: 1.8vh;
        line-height: 1.5em;
        font-weight: 100;
        // border: salmon 1px solid;
        display: flex;
        // justify-content: center;
        // align-content: center;
        align-items: center;

        // .info {
        //     // border: salmon 1px solid;
        // }
    }

    .illustration {
        flex: 0.8;
        display: flex;
        min-height: 40vh;
        padding: 5vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0vh 2vh 2vh 0vh;
        cursor: pointer;
    }
}

@media (max-width: 600px) {
    .info-block-wrapper {
        flex-direction: column;
        box-shadow: 0.1vh 0.1vh 3vh rgba(128, 128, 128, 0.397);
        border-radius: 2vh;

        &.text-right {
            flex-direction: column-reverse;
            // flex-direction: column;
            .illustration {
                border-radius: 2vh 2vh 0vh 0vh;
            }
        }

        &.vertical {
        }

        .info-wrapper {
            flex: 1;
            padding: 8vh;
            font-size: 2.8vh;
            line-height: 1.5em;
            font-weight: 100;
            align-items: center;
        }

        .illustration {
            border-radius: 0vh 0vh 2vh 2vh;
            min-height: 55vh;
        }
    }
}
