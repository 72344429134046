.page-about {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    .blobs {
        height: 100%;
        width: 100vw;
        margin: 15vw 0vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-top: -1vw;

        svg {
            width: 100%;
            #mint,
            #mint-gradient {
                animation: blob-appear 0.9s ease-in-out;
                animation-fill-mode: forwards;
                opacity: 0;
            }

            #triple,
            #triple_little_gradient {
                animation: blob-appear3 0.9s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.2s;
                opacity: 0;
            }

            #grapefruit {
                animation: blob-appear 0.9s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.3s;
                opacity: 0;
            }

            #green-beach,
            #green-beach-gradient {
                animation: blob-appear2 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.3s;
                opacity: 0;
            }

            #raspberry {
                animation: blob-appear3 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #light-pink {
                animation: blob-appear2 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #lime {
                animation: blob-appear3 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #pinky-sky {
                animation: blob-appear3 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #candy {
                animation: blob-appear2 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.3s;
                opacity: 0;
            }

            #bonfire {
                animation: blob-appear 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #snow-left {
                animation: blob-appear3 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }
            #snow-bottom {
                animation: blob-appear2 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #citrus,
            #citrus-gradient {
                animation: blob-appear 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #lollypop {
                animation: blob-appear2 0.9s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #sunrise {
                animation: blob-appear3 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #snow-top {
                animation: blob-appear3 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.2s;
                opacity: 0;
            }

            #snow-central {
                animation: blob-appear2 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }

            #little-lollypop {
                animation: blob-appear 1.1s ease-in-out;
                animation-fill-mode: forwards;
                animation-delay: 0.1s;
                opacity: 0;
            }
        }
    }

    .avatar {
        height: 25vh;
        width: 25vh;
        // width: var(--foo);
        border-radius: 100%;
        background-image: url(./icons/vitya.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        top: 35.4vh;
        left: 48.6vw;
        position: absolute;
        border: var(--bar) px solid aquamarine;
        transform: scale(1);
        animation: gaining 1.1s ease-in;
        animation-fill-mode: towards;
    }

    .biography,
    .biography2 {
        position: absolute;
        max-width: 40vw;
        background-repeat: no-repeat;
        background-size: contain;
        backdrop-filter: blur(10px);
        border-radius: 59% 52% 53% 60%/45% 53% 52% 59%;
        padding: 2.5em;
        display: flex;
        flex-direction: column;
        text-align: center;
        animation: appearing 2s ease-in;

        p {
            font-size: 1.1vw;
            margin-bottom: 1em !important;
        }
    }

    .biography {
        bottom: 3vw;
        left: 3vw;
    }

    .biography2 {
        top: 5vw;
        right: 0vw;
    }
}
@keyframes gaining {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes blob-appear {
    0% {
        transform: translate(var(--blob-pos3), var(--blob-pos2));
        opacity: 0;
        // transform: translate3d(42vw, -62vw, -135vw);
    }

    100% {
        transform: translate(0vw, 0vw);
        opacity: 1;
        // transform: translate3d(0vw, 0vw, 0vw);
    }
}

@keyframes blob-appear2 {
    0% {
        transform: translate(var(--blob-pos2), var(--blob-pos1));
        opacity: 0;
        // transform: translate3d(12vw, 32vw, 13vw);
    }

    100% {
        transform: translate(0vw, 0vw);
        opacity: 1;
        // transform: translate3d(0vw, 0vw, 0vw);
    }
}

@keyframes blob-appear3 {
    0% {
        transform: translate(var(--blob-pos), var(--blob-pos1));
        opacity: 0;

        // transform: translate3d(-92vw, 2vw, -75vw);
    }

    100% {
        transform: translate(0vw, 0vw);
        opacity: 1;
        // transform: translate3d(0vw, 0vw, 0vw);
    }
}
