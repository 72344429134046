/* @font-face {
    font-family: 'Forward-extra';
    src: url('./fonts/Forward-extra.otf');
} */

/* @font-face {
    font-family: 'Forward';
    src: url('./fonts/Forward.otf');
} */

div {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

body {
    --mega-padding: 7vw;
    font-family: sans-serif;

    --page-padding: 25vw 10vw 10vw 10vw;
    --page-padding-top: 25vw;
    --page-padding-bottom: 8vw;
    --equal-page-padding: 10vw;
    --mini-page-padding: 35vw 5vw 5vw 5vw;

    --global-shadow: 0vw 0vw 3.906vw rgba(0, 0, 0, 0.219);
    position: relative;
    letter-spacing: 0.04em;
    overflow-x: hidden !important;
    font-family: 'Comfortaa', sans-serif;

    --planet-shadow: 0vw 0vw 1.906vw rgba(0, 0, 0, 0.219);
    --middle-adaptive: 800px;
}

.page {
    min-height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    // background-position: 10vw -20vw;
    // background-size: 72%;
}

h1 {
    font-size: 5.3vw;
    font-weight: 500;
    margin-bottom: 0.5em;
    // color: transparentize($color: #3b2275, $amount: 0);
    position: relative;
    background: -webkit-linear-gradient(to bottom, #000000 0%, #386ca8 90%);
    background: -moz-linear-gradient(to bottom, #000000 0%, #386ca8 90%);
    background: linear-gradient(to bottom, #000000 0%, #386ca8 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h2,
a.title-link {
    font-size: 3vw;
    font-weight: 400;
    // margin-bottom: 1.2em;
    margin: 0.8em 0em;
    color: teal;
    line-height: 1.25em;

    background: -webkit-linear-gradient(to bottom, #286b63 0%, #20e7cf 96%);
    background: -moz-linear-gradient(to bottom, #286b63 0%, #20e7cf 96%);
    background: linear-gradient(to bottom, #286b63 0%, #20e7cf 96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p {
    font-size: 1.6vw;
    font-weight: 100;
    line-height: 1.4em;
    margin-bottom: 1.8em;

    &:last-child {
        margin-bottom: 0;
    }
}

strong {
    font-weight: bold;
}

@keyframes appearing {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(-180deg);
        background-color: rgb(255, 227, 255);
    }

    25% {
        background-color: rgb(187, 197, 255);
    }

    50% {
        background-color: rgb(130, 238, 215);
    }

    54% {
        background-color: rgb(217, 255, 0);
    }

    60% {
        background-color: rgb(201, 208, 247);
    }

    65% {
        background-color: rgb(60, 192, 181);
    }

    70% {
        background-color: rgb(209, 237, 255);
    }

    80% {
        background-color: rgb(122, 96, 240);
    }

    90% {
        background-color: rgb(255, 255, 255);
    }

    100% {
        transform: rotate(180deg);
        background-color: rgb(255, 227, 255);
    }
}

@keyframes rotate2 {
    0% {
        transform: rotate(-180deg);
        background-color: rgb(59, 61, 223);
        background: linear-gradient(to right, #9c51ff 12%, orange 63%);
    }

    25% {
        background-color: rgb(187, 197, 255);
    }

    50% {
        background-color: rgb(43, 255, 209);
    }

    54% {
        background-color: rgb(255, 187, 0);
    }

    60% {
        background-color: rgb(201, 208, 247);
    }

    65% {
        background-color: rgb(60, 192, 181);
    }

    70% {
        background-color: rgb(255, 255, 255);
    }

    80% {
        background-color: rgb(122, 96, 240);
    }

    90% {
        background-color: rgb(85, 255, 246);
    }

    100% {
        transform: rotate(180deg);
        background-color: rgb(33, 133, 119);
    }
}

@keyframes rotate3linear {
    0% {
        transform: rotate(0deg) scale(0.9);
        background: linear-gradient(to right, #9c51ff 12%, rgb(255, 222, 172) 63%);
    }

    20% {
        background: linear-gradient(to right, #3a43c5 12%, rgb(255, 207, 119) 63%);
        transform: scale(1);
    }

    50% {
        background: linear-gradient(to right, #4f3ac5 12%, rgb(255, 216, 190) 63%);
        transform: rotate(360deg) scale(1.3);
    }

    65% {
        background: linear-gradient(to right, #6b46c0 12%, rgb(255, 242, 217) 63%);
        transform: scale(1.15);
    }

    80% {
        background: linear-gradient(to right, #0a75bd 12%, rgb(255, 200, 159) 63%);
        transform: scale(1);
    }

    100% {
        transform: rotate(0deg) scale(0.8);
        background: linear-gradient(to right, black 12%, red 63%);
        background: linear-gradient(to right, #5184b3 12%, orange 63%);
    }
}

// @keyframes rotate3linear {
//     0% {
//         transform: rotate(0deg) scale(0.8);
//         background: linear-gradient(to right, rgb(255, 242, 217) 63%, #9c51ff 12%);
//     }

//     20% {
//         background: linear-gradient(to right, rgb(255, 207, 119) 63%, #3a43c5 12%);
//         transform: scale(1);
//     }

//     50% {
//         background: linear-gradient(to right, rgb(255, 216, 190) 63%, #4f3ac5 12%);
//         transform: rotate(360deg) scale(1.3);
//     }

//     65% {
//         background: linear-gradient(to right, rgb(255, 242, 217) 63%, #6b46c0 12%);
//     }

//     80% {
//         background: linear-gradient(to right, rgb(255, 200, 159) 63%, #0a75bd 12%);
//         transform: scale(1);
//     }

//     100% {
//         transform: rotate(0deg) scale(0.65);
//         background: linear-gradient(to right, orange 63%, #5184b3 12%);
//     }
// }

// @keyframes rotate3linear {
//     0% {
//         transform: rotate(0deg) scale(0.8);
//         background: linear-gradient(to left, rgb(255, 242, 217) 22%, #9c51ff 43%);
//     }

//     30% {
//         background: linear-gradient(to left, rgb(255, 207, 119) 22%, #6f8cdd 43%);
//         transform: scale(1);
//     }

//     50% {
//         background: linear-gradient(to left, rgb(245, 176, 87) 22%, #5a46c7 43%);
//         transform: rotate(360deg) scale(1.3);
//     }

//     65% {
//         background: linear-gradient(to left, orange 22%, #6b46c0 43%);
//     }

//     80% {
//         background: linear-gradient(to left, rgb(253, 175, 115) 22%, #0a75bd 43%);
//         transform: scale(1);
//     }

//     100% {
//         transform: rotate(0deg) scale(0.65);
//         background: linear-gradient(to left, rgb(255, 242, 217) 22%, #5184b3 63%);
//     }
// }
