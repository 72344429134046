.full-size-wrapper {
    height: 100vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 100;
    margin: auto 0;
    padding: 0 !important;
    overflow-y: hidden;

    &.not-visible {
        display: none;
    }

    .close {
        height: 3vw;
        width: 3vw;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        border: none;
        outline: none;
        z-index: 101;
        mask-image: url(~icons/close.svg);
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: center;
        // background-color: rgba(255, 255, 255, 0);
        background: linear-gradient(25deg, rgb(18, 141, 255) 3%, rgb(255, 196, 0) 80%);
        // opacity: 0.2;
        margin: 1vw;
    }

    .carousel {
        height: 100vh;
        width: 100vw;

        .carousel-scroller {
            .carousel-item {
                height: 100vh;
                width: 100vw;

                & > * {
                    flex: 1;
                    margin-right: 0.1vw;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }

        .carousel-pagination-wrapper {
            position: absolute;
            width: 100%;
            top: 46%;

            .right-button,
            .left-button {
                height: 4vw;
                width: 4vw;
                position: absolute;
                z-index: 102;
                opacity: 0.4;
            }

            .left-button {
                left: 0;
            }

            .right-button {
                right: 0;
            }
        }
    }
}
