.chaotic-pic {
    // width: 100%;
    // display: grid;
    // grid-template-columns: 3fr 1fr 1fr;
    // grid-template-rows: 1fr 3fr;
    // grid-column-gap: 10px;
    // grid-row-gap: 10px;
    // min-height: 50vh;
    margin: 10vh 0vh;

    & > div {
        margin: var(--grid-padding) 0vh;
    }

    .block1 {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr 4fr;
        grid-column-gap: var(--grid-padding);
        grid-row-gap: var(--grid-padding);
        // min-height: 40vh;
        // border: 2px solid springgreen;

        & > div,
        & > * {
            // border: 2px solid lightpink;
            height: 20vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            // background-color: red;
            // background-image: url(./images/one.jpg);
        }
    }

    .block2 {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 3fr 1fr;
        grid-column-gap: var(--grid-padding);
        grid-row-gap: var(--grid-padding);
        // min-height: 50vh;
        // margin-bottom: 10px;

        & > div {
            cursor: pointer;
        }

        & > div,
        & > * {
            // border: 2px solid lightpink;
            height: 40vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        & .a2 {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            cursor: default;
            grid-column-gap: var(--grid-padding);
            grid-row-gap: var(--grid-padding);

            > div {
                // border: 2px solid rgb(166, 255, 243);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }
        }
    }

    .block3 {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 1fr;
        // grid-template-rows: 16fr 8fr;
        grid-column-gap: var(--grid-padding);
        grid-row-gap: var(--grid-padding);
        // min-height: 50vh;
        // margin-bottom: 10px;

        & > div,
        & > * {
            // border: 2px solid lightpink;
            height: 43vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
        }
    }
}
@media (max-width: 800px) {
    .chaotic-pic {
        .block1 {
            grid-template-columns: 8fr;
            grid-column-gap: 5vh;
            // grid-row-gap: var(--grid-padding);

            // border: 4px solid rgb(108, 47, 221);

            .block1three {
                grid-row-gap: 6fr;
            }

            & > div,
            & > * {
                height: 50vh;
            }
        }

        .block2 {
            grid-template-columns: 1fr;
            // border: 4px solid rgb(187, 255, 0);

            > div {
                height: 70vh;
            }

            .a2 {
                grid-template-columns: 1fr 1fr;
                // grid-template-rows: none;
                // grid-row-gap: 0vw;
                height: 100%;

                > div {
                    height: 30vh;
                }
            }
        }

        .block3 {
            grid-template-columns: 1fr;

            > div {
                height: 60vh;
            }
        }
    }
}
