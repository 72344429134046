.page-music {
    // --video-container: 40vw;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    .planet-wrapper {
        .inner-halo {
            z-index: 1;
            box-shadow: inset 0vw 0vw 4vw 5.2vw rgb(255, 255, 255);
        }
    }

    .music-titles-wrapper {
        padding: 0 var(--equal-page-padding);
    }

    .video-container {
        position: relative;
        min-height: 23vw;
        margin-top: 5vw;
        margin-bottom: 7vw;
        width: 100vw;

        .video-js {
            margin-bottom: 5vw;
        }
    }

    .music-container {
        // margin-top: calc(var(--video-container) * 1.5);
        padding: 0 var(--equal-page-padding);
    }
}

@media (max-aspect-ratio: 8/7) {
    .page-music {
        padding: 23vh 0vh; /* ЭТО НЕ ТРОГАЕМ, иначе лишний пэддинг у100% элементов */

        .planet-wrapper {
            .planet,
            .inner-halo {
                top: -34.5vw;
            }
        }
        .music-titles-wrapper {
            padding: 2vh 7vh;
            // border: 1px solid rgb(140, 255, 240);
        }
    }
}

@media (max-aspect-ratio: 8/13) {
    .page-music {
        .planet-wrapper {
            .planet,
            .inner-halo {
                height: 34vw;
                width: 34vw;
            }
        }
        .music-titles-wrapper {
            padding: 0vh 6.5vh;
            // border: 1px solid rgb(255, 140, 140);
        }

        .video-container {
            height: 30vh;
        }

        .music-container {
            margin-top: calc(var(--video-container) * 1.8);
        }
    }
}
