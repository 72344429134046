.planet-wrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    animation: appearing 1.75s ease-in-out;

    .planet-blur {
        position: absolute;
        height: 55vw;
        width: 55vw;
        border-radius: 100%;
        top: -48vw;
        right: 23vw;
        background: linear-gradient(0.65turn, rgb(134, 153, 158) 2%, #13434e00);
        filter: blur(6vw);
        z-index: 0;
    }

    .inner-halo,
    .planet,
    .ink-planet {
        position: absolute;
        left: 45vw;
        top: -35vw;
        height: 32vw;
        width: 32vw;
        border-radius: 100%;
    }

    .planet {
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: center;
        animation-fill-mode: forwards;
        animation: rotate 35s linear infinite;
        z-index: 0;

        &.hidden {
            animation: none;
        }
    }

    .ink-planet {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 0;
        background-size: 70%;
        transform: rotate(318deg);
        animation: color-metamorphosis 30s linear infinite;
        animation-fill-mode: forwards;

        &.hidden {
            animation: none;
        }
    }

    .inner-halo {
        z-index: 1;
        box-shadow: inset 0vw 0vw 4vw 4.5vw rgb(255, 255, 255);
    }
}

@keyframes color-metamorphosis {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(360deg);
    }

    100% {
        filter: hue-rotate(0deg);
    }
}
