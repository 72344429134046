.page-visual-art-wrapper {
    // padding: var(--page-padding);
    --grid-padding: 1.5vh;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);
    // padding-bottom: var(--page-padding-bottom);

    .planet-wrapper {
        .ink-planet {
        }
    }

    .visual-titles-wrapper,
    .sculptures-wrapper,
    .chaotic-pic,
    & > h2 {
        padding: 0 var(--equal-page-padding);
    }

    // .chaotic-pic {
    //     padding: 0 var(--equal-page-padding);
    // }

    // & > h2 {
    //     padding: 0 var(--equal-page-padding);
    // }

    .mountains {
        width: 100%;
        transform: scale(1.3);
        height: 55vh;
        background-image: url(~icons/mountains.png);
        background-repeat: no-repeat;
        // background-size: 100%;
        background-size: cover;
        background-position: center;
    }

    .sculpture-h2 {
        margin-top: 15vh;
    }
}

@media (max-width: 800px) {
    .page-visual-art-wrapper {
        // padding: var(--mini-page-padding);
        --grid-padding: 1.5vh;
    }
}

@media (max-aspect-ratio: 8/7) {
    .page-visual-art-wrapper {
        padding: 23vh 0vh; /* ЭТО НЕ ТРОГАЕМ, иначе лишний пэддинг у100% элементов */

        .planet-wrapper {
            .planet,
            .inner-halo {
                top: -34.5vw;
            }
        }
        .visual-titles-wrapper {
            padding: 2vh 7vh;
            // border: 1px solid rgb(140, 255, 240);
        }
    }
}

@media (max-aspect-ratio: 8/13) {
    .page-visual-art-wrapper {
        .planet-wrapper {
            .planet,
            .inner-halo {
                height: 34vw;
                width: 34vw;
            }
        }
        .visual-titles-wrapper {
            padding: 0vh 6.5vh;
            // border: 1px solid rgb(255, 140, 140);
        }

        .video-container {
            height: 30vh;
        }

        .music-container {
            margin-top: calc(var(--video-container) * 1.8);
        }
    }
}
