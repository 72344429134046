.audio-track-wrapper {
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 1.2vw;
        margin-bottom: 1em;
    }

    .audio-track-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        // button {
        //     height: 3vw;
        //     width: 3vw;
        //     margin-right: 1vw;
        //     border: none;
        //     color: rgb(9, 126, 110);
        //     cursor: pointer;
        //     background-image: url(~icons/play.svg);
        //     background-repeat: no-repeat;
        //     background-position: left;
        //     background-color: transparent;
        //     background-size: contain;
        // }

        .playing-song,
        .paused-song {
            background-repeat: no-repeat;

            background-color: transparent;
            background-size: contain;
            border: none;
            margin-right: 2vh;
            width: 3vh;
            height: 3vh;
            cursor: pointer;
        }

        .paused-song {
            background-image: url(~icons/play.svg);
            background-position: left;
        }

        .playing-song {
            background-image: url(~icons/pause.svg);
            background-position: center;
        }

        .progress-bar-wrapper {
            width: 80%;
            height: 1.5vw;
            position: relative;

            .progress-bar {
                // width: 90%;
                // height: 1.5vw;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, #ffc800 6%, #aefffb, #335d86);
                border-radius: 1vw;
                display: flex;
                align-items: center;
                cursor: pointer;

                mask-image: url(./icons/progress_bar.svg);
                mask-position: center;
                mask-size: cover;
                mask-repeat: no-repeat;
            }

            .slider {
                height: 4vh;
                width: 0.3vh;
                background: radial-gradient(#ffc400, #188275);
                border-radius: 25%;
                transform-origin: center center;
                position: absolute;
                margin-left: -1.25vw;
                top: -0.9vh;
            }
        }
    }
}
