.sculptures-wrapper {
    // border: 4px solid red;
    width: 100%;
    // height: 20vh;
    margin-top: 10vh;

    & > div {
        margin-bottom: 4vh;
    }
}
