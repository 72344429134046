.page {
    position: relative;
    overflow: hidden;

    .main-wrapper {
        font-family: 'Comfortaa', cursive;
        position: relative;
        background-image: url(/icons/circular_shadow.svg);
        background-repeat: no-repeat;
        background-size: 130vh;
        background-position: 10vw -50vh;
        left: 0%;
        top: -60%;
        user-select: none;
        min-width: 620px;

        // --fluid-border-radius: 8vw 8vw 0vw 0vw;
        --fluid-border-radius: 50% 20% 70% 50% / 30% 20% 130% 70%;

        .background-planet {
            background-image: url(~/icons/motherboard_transparent.svg);
            background-repeat: no-repeat;
            height: 33vw;
            width: 33vw;
            background-size: contain;
            background-position: center;
            left: 17vw;
            top: -23vw;
            position: absolute;
            z-index: 10;
            border-radius: 100%;
            box-shadow: inset 0vw 0vw 4.2vh 7.5vh rgb(255, 255, 255), -2px -3px rgb(255, 255, 255);
        }

        .planetary-menu {
            height: 100vh;
            display: flex;
            flex-direction: row;
            position: relative;
            // z-index: 12 !important;

            .research-and-music {
                display: flex;
                flex: 1;
                flex-direction: column;
            }
        }

        .fluid-container {
            // border: 1px solid black;
            width: 93vh;
            height: 75vh;
            // transform: rotate(32deg);
            transform: rotate(42deg);
            // border-radius: var(--fluid-border-radius);
            border-radius: 100%;
            z-index: 5;
            bottom: -19vh;
            left: -19vh;
            position: absolute;
            // box-shadow: 0vw 0vw 0.9vw 0.5vw rgb(255, 255, 255);
            box-shadow: 0vw 0vw 0.9vh 0.5vh rgb(255, 255, 255);

            &::after {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                // border-radius: var(--fluid-border-radius);
                border-radius: 100%;
                pointer-events: none;
                // box-shadow: inset 0vw 0vw 3.8vw 4.5vw rgb(255, 255, 255),
                //     -2px -3px rgb(255, 255, 255);
                box-shadow: inset 0vw 0vw 3.8vh 4.5vh rgb(255, 255, 255),
                    -2px -3px rgb(255, 255, 255);
                opacity: 1;
            }

            .fluid-background {
                height: 100%;
                width: 100%;
                border-radius: var(--fluid-border-radius);
                border-radius: 100%;
            }
        }
    }
}

@media (max-aspect-ratio: 8/6) {
    .page {
        .main-wrapper {
            .background-planet {
            }

            .planetary-menu {
            }

            .fluid-container {
                width: 80vh;
                height: 70vh;
                left: -22vh;
                bottom: -27vh;
            }
        }
    }
}
@media (max-aspect-ratio: 15/16) {
    .page {
        .main-wrapper {
            .background-planet {
                height: 38vw;
                width: 38vw;
                background-size: contain;
                background-position: center;
                left: 15vw;
                top: -23vw;
            }

            .planetary-menu {
            }

            .fluid-container {
                width: 73vh;
                height: 75vh;
                left: -23vh;
            }
        }
    }
}
