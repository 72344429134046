.notes-background-container {
    width: 55vw;
    height: 60vh;
    position: absolute;
    bottom: 0;
    // right: 0%;
    // right: -20%;
    // right: -20vh;
    right: -6vw;
    z-index: 1;
    // border: 1px solid aquamarine;
    overflow: hidden;
    // background: linear-gradient(orange, rgb(20, 120, 128), rgb(255, 180, 42));
    // background-image: url(../images/notes.svg);
    // background-size: 131% 173%;
    // background-repeat: no-repeat;
    // background-size: cover;

    // mask-image: url(../images/circle_notes.svg);
    // mask-repeat: no-repeat;
    transform: scale(1.3);

    // animation: rotate 25s linear infinite;
    // animation-fill-mode: forwards;

    .notes-background {
        position: absolute;
        width: 50vw;
        height: 50vw;
        right: -6%;
        bottom: -62%;
        background-color: rgb(212, 212, 212);
        mask-image: url(../images/notes.svg);
        mask-repeat: no-repeat;
        mask-size: 92vh;
        mask-position: right bottom;
        z-index: 1;
    }
}

@media (max-aspect-ratio: 8/6) {
    .notes-background-container {
        .notes-background {
            width: 70vh;
            height: 80vh;
            right: -6%;
            bottom: -62%;
        }
    }
}

@media (max-aspect-ratio: 15/16) {
    .notes-background-container {
        .notes-background {
            width: 70vh;
            height: 90vh;
            bottom: -60%;
        }
    }
}
