.note-gen-wrapper {
    width: 55vw;
    height: 50vh;
    position: absolute;
    bottom: 0;
    right: 0%;
    z-index: 1;
    // border: 1px solid aquamarine;
    // background-color: rgb(255, 0, 0);
    // background: linear-gradient(orange, rgb(20, 120, 128), rgb(255, 180, 42));
    // // background-image: url(../images/notes.svg);
    // // background-size: 131% 173%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // mask-image: url(../images/notes.svg);
    // mask-repeat: no-repeat;
    // mask-size: cover;
    // background-position: 23% -15%;

    .notes-container {
        // height: 10vw;
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
        // mask-image: url(../images/notes.svg);
        // mask-repeat: no-repeat;
        // mask-size: cover;
        // background-image: url(../images/notes.svg);
        // background-size: 100% 180%;
        // background-repeat: no-repeat;
        // background-position: 10% 0%;

        .note {
            position: absolute;

            &::before {
                content: '';
                position: relative;
                height: var(--note-size);
                width: var(--note-size);
                background-image: var(--note-image);
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                animation: pulse-alert 1s ease-in-out;
                opacity: var(--note-opacity);
                animation-fill-mode: forwards;
            }
        }
    }
}

@keyframes pulse-alert {
    0% {
        transform: scale(0) translate(0px, 0px);
        // opacity: 0;
    }
    25% {
        transform: scale(1) translate(25px, -25px);
    }
    50% {
        transform: scale(1.2) translate(50px, -50px);
        // opacity: 1;
    }
    75% {
        transform: scale(1) translate(0px, -10px);
    }
    100% {
        transform: scale(0) translate(30px, -100px);
        // opacity: 0;
    }
}
