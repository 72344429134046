.player-background {
    position: fixed;
    height: 45vh;
    width: 45vh;
    display: block;
    background-image: url(./icons/player_background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 5;
}

.play-button-container {
    position: relative;
    z-index: 6;

    &.is-playing {
        .record-one {
            transform: rotate(90deg);
            animation: record-moving backwards 2s linear infinite;
        }
        .record-two {
            // animation: record-moving backwards 2s linear infinite;
        }
    }

    .record-one,
    .record-two {
        height: 8vh;
        width: 8vh;
        background-image: url(./icons/record.svg);
        // background-image: url(./icons/pure_record.svg) !important;
        background-size: 99%;
        background-repeat: no-repeat;
        display: block;
        position: fixed;
        bottom: 3.3vh;
        right: 6vh;
        z-index: 4;
        cursor: pointer;
    }

    .record-two {
        // background-image: url(./icons/pure_record.svg) !important;
        transform: rotate(70deg) !important;
    }

    .play-button,
    .pause-button {
        display: block;
        bottom: 6.1vh;
        z-index: 6;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
        position: fixed;
    }

    .play-button {
        background-image: url(~icons/play.svg);
        width: 2.4vh;
        height: 2.4vh;
        right: 8.6vh;
    }

    .pause-button {
        background-image: url(~icons/pause.svg);
        right: 8.9vh;
        width: 2.3vh;
        height: 2.3vh;
    }
}

@keyframes record-moving {
    0% {
        transform: translate(0px, 0px);
        // opacity: 0;
    }
    25% {
        transform: translate(1px, 1px);
    }
    50% {
        transform: translate(0px, 1px);
        // opacity: 1;
    }
    75% {
        transform: translate(-1px, 1px);
    }
    100% {
        transform: translate(0px, 0px);
        // opacity: 0;
    }
}

@media (max-aspect-ratio: 8/8) {
    .player-background {
        height: 35vh;
        width: 35vh;
    }

    .play-button-container {
        &.is-playing {
            .record-one {
            }
            .record-two {
            }
        }

        .record-one,
        .record-two {
            height: 6vh;
            width: 6vh;

            bottom: 3.3vh;
            right: 5vh;
        }

        .record-two {
        }

        .play-button,
        .pause-button {
            bottom: 5.25vh;
        }

        .play-button {
            width: 2vh;
            height: 2vh;
            right: 6.85vh;
        }

        .pause-button {
            right: 7.05vh;
            width: 2vh;
            height: 2vh;
        }
    }
}
