.menu-wrapper {
    position: absolute;
    z-index: 11;

    &.transparent {
        .menu-background {
            background-image: url(./images/menu_transparent.svg);
            position: fixed;
        }
    }

    .menu-background {
        width: 56vh;
        height: 53vh;
        position: fixed;
        // z-index: 3;
        z-index: 11;
        top: 0;
        left: -2.1vh;
        background-image: url(~icons/menu.svg);
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        pointer-events: none;
    }

    ul {
        display: flex;
        flex-direction: column;
        padding: 2em 3.3em;
        font-size: 1.5vh;
        position: fixed;
        transition: color 0.3s ease-in-out;
        z-index: 11;
        // border: 1px solid springgreen;

        > a {
            margin-bottom: 1em;
            color: black;
            position: relative;
            display: block;
            pointer-events: all;
            // z-index: 11;

            // text-shadow: blueviolet 5px 5px;

            &.active {
                // text-shadow: rgb(255, 218, 6) 0.1vw 0.1vw;

                &:hover {
                    // text-shadow: rgb(255, 64, 6) 0.2vw 0.2vw;

                    &::after {
                        color: aquamarine;
                        position: relative;
                        // content: attr(data-descr);
                        content: attr(data-descr);
                        font-size: 1em;
                        position: absolute;
                        left: 1.5%;
                        top: 1%;
                        white-space: nowrap;
                        // text-shadow: rgb(6, 255, 193) 0.6vw 0.6vw;
                    }
                }

                &::after {
                    position: relative;
                    color: #e78c15;
                    // color: #cff605;
                    white-space: nowrap;
                    // content: attr(data-descr);
                    content: attr(data-descr);
                    font-size: 1em;
                    position: absolute;
                    left: 0.085em;
                    top: 0em;
                    z-index: 11;
                    // text-shadow: rgb(6, 255, 193) 0.01em 0.01em;
                    // text-shadow: rgb(255, 168, 6) 0.05em 0.01em;
                }
            }

            &:hover {
                &::after {
                    color: aquamarine;
                    // color: rgb(37, 221, 212);
                    position: relative;
                    // content: attr(data-descr);
                    content: attr(data-descr);
                    font-size: 1em;
                    position: absolute;
                    left: 1.3%;
                    top: 1%;
                    animation: appearing 0.25s ease-in-out;
                    white-space: nowrap;
                    transition: color 0.3s ease-in-out;
                    // text-shadow: rgb(6, 255, 193) 0.6vw 0.6vw;
                }
            }
        }

        // & > [data-descr]::before {
        //     color: aquamarine;
        //     position: relative;
        //     content: attr(data-descr);
        //     font-size: 1vw;
        //     position: absolute;
        //     left: 14px;
        //     top: 4px;
        //     text-shadow: rgb(31, 6, 255) 0.5vw 0.5vw;
        //     content: ' (' attr(data-descr) ')';
        // }
    }

    h1 {
        color: red;
        font-size: 4vw;
    }
}

@media (max-aspect-ratio: 8/7) {
    .menu-wrapper {
        &.transparent {
            .menu-background {
            }
        }

        .menu-background {
            width: 40vh;
            height: 37vh;
            top: 0;
            left: -2.1vh;
        }

        ul {
            padding: 2em 2.1em;
            font-size: 1.13vh;
        }
    }
}

// @media (max-aspect-ratio: 8/8) {
//     .menu-wrapper {
//         &.transparent {
//             .menu-background {
//             }
//         }

//         .menu-background {
//             width: 36vh;
//             height: 33vh;
//             top: 0;
//             left: -2.1vh;
//         }

//         ul {
//             padding: 2.2em 2.1em;
//             font-size: 1vh;
//         }
//     }
// }
