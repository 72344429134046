.planetary-menu {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 14vh;

    .art-planet,
    .music-planet,
    .research-planet,
    .logo {
        width: 19vh;
        height: 19vh;
        position: relative;
        background-color: white;
        cursor: pointer;
        color: black;
        box-shadow: var(--planet-shadow);
        border-radius: 100%;
        z-index: 9;

        span {
            font-size: 3vh;
            white-space: nowrap;
            position: absolute;
            font-weight: 600;
        }

        &::after {
            position: relative;
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            height: 10vh;
            width: 28vh;
            display: block;
        }
    }

    .research-planet {
        top: 22%;
        // left: 56%;

        span {
            top: 45%;
            left: 18%;
        }

        &::after {
            background-image: url(./images/research_decor.svg);
            // border: 1px solid rebeccapurple;
            left: 28%;
            top: 40%;
        }
    }

    .art-planet,
    .menu-item-_visual {
        top: 37%;
        // left: -8.5%;
        left: -4.5%;
        position: relative;
        // z-index: 5;
        // z-index: 9;
        cursor: pointer;

        span {
            top: 45%;
            left: 18%;
        }

        &::after {
            background-image: url(./images/art_decor.svg);
            left: -24%;
        }
    }

    .logo {
        // flex: 0.5;
        box-shadow: var(--planet-shadow);
        // position: absolute;
        height: 29vh;
        width: 29vh;
        top: 40%;
        cursor: auto;
        position: relative;
        // z-index: 9;
        // left: 42.5%;

        font-weight: 600;

        span {
            position: absolute;
            top: 42%;
            left: 15%;
            font-size: 5vh;
        }
    }

    .music-planet {
        top: 73%;
        left: -30vh;
        // z-index: 2;
        z-index: 9;

        span {
            top: 44%;
            left: 21%;
        }

        &::after {
            background-image: url(./images/music_decor.svg);
            top: 40%;
            left: -25%;
        }
    }
}

// @media (max-aspect-ratio: 15/16) {
//     .planetary-menu {
//         .art-planet,
//         .music-planet,
//         .research-planet,
//         .logo {
//             width: 15vh;
//             height: 15vh;

//             span {
//                 font-size: 2vh;
//             }
//         }
//     }
// }

@media (max-width: 600px) {
    .planetary-menu {
        margin-left: 12vh;
        .art-planet,
        .music-planet,
        .research-planet,
        .logo {
            width: 15vh;
            height: 15vh;

            span {
                font-size: 2vh;
            }
        }

        .research-planet {
            top: 25%;
            left: -7%;

            span {
                top: 45%;
                left: 18%;
            }

            &::after {
                left: 10%;
                top: 37%;
            }
        }

        .art-planet {
            top: 37%;
            left: -4.5%;

            span {
                top: 45%;
                left: 18%;
            }

            &::after {
                left: -50%;
                top: -2%;
            }
        }

        .logo {
            height: 20vh;
            width: 20vh;
            top: 40%;

            span {
                top: 42%;
                left: 15%;
                font-size: 3.3vh;
            }
        }

        .music-planet {
            top: 63%;
            left: -26vh;

            span {
                top: 44%;
                left: 27%;
            }

            &::after {
                top: 36%;
                left: -25%;
            }
        }
    }
}
