.sketches-wrapper {
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    width: 100%;
    height: 45vw;
    margin: 10vh 0vh;
    // border: 5px solid yellow;

    .carousel {
        // border: 6px salmon solid;
        height: 100%;

        .carousel-scroller {
            // border: 6px blue solid;
            height: 100%;
            // box-shadow: 0em 0.75em 2em rgba(5, 29, 65, 0.08);

            .carousel-item {
                // border: 6px solid rgb(172, 99, 255);
                display: flex;
                cursor: pointer;

                .sketch-image {
                    // border: 6px solid pink;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .sketches-wrapper {
        height: 85vh;
    }
}
